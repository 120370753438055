import axios from "axios";

export const AddCustomer = async (payload) => {
  try {
    return await axios.post(`/customers/create`, payload);
  } catch (error) {
    return error;
  }
};

export const AddSupplier = async (payload) => {
  try {
    return await axios.post(`/suppliers/create`, payload);
  } catch (error) {
    return error;
  }
};

export const UpdateCustomer = async (payload) => {
  try {
    return await axios.put(`/customers/update/${payload.id}`, payload);
  } catch (error) {
    return error;
  }
};

export const UpdateSupplier = async (payload) => {
  try {
    return await axios.put(`/suppliers/update/${payload.id}`, payload);
  } catch (error) {
    return error;
  }
};

export const GetAllCustomers = async (params) => {
  try {
    return await axios.get("/customers", { params });
  } catch (error) {
    return error;
  }
};

export const GetAllSuppliers = async (params) => {
  try {
    return await axios.get("/suppliers", { params });
  } catch (error) {
    return error;
  }
};

export const GetSingleCustomer = async (payload) => {
  try {
    return await axios.get(`/customers?id=${payload}`);
  } catch (error) {
    return error;
  }
};

export const GetSingleSupplier = async (payload) => {
  try {
    return await axios.get(`/suppliers?id=${payload}`);
  } catch (error) {
    return error;
  }
};

export const DeleteCustomer = async (payload) => {
  try {
    return await axios.delete(`/customers/delete/${payload.id}`);
  } catch (error) {
    return error;
  }
};

export const DeleteSupplier = async (payload) => {
  try {
    return await axios.delete(`/suppliers/delete/${payload.id}`);
  } catch (error) {
    return error;
  }
};

export const SearchCustomer = ({ text }) => {
  return axios.get(`/customers/search`, {
    params: { text },
  });
};

export const SearchSupplers = ({ text }) => {
  return axios.get(`/suppliers/search`, {
    params: { text },
  });
};

export const UploadBulkCustomers = async (payload) => {
  return await axios.post(`import/waves/customers`, payload);
};
