<template>
  <!-- order items modal -->
  <b-modal
    id="delete-journal-row-modal"
    ref="deleteManualJournalModal"
    title="Delete Journal"
    size="lg"
    header-class="font-primary"
    header-bg-variant="danger"
    header-text-variant="white"
    header-border-variant="white"
    footer-bg-variant="light"
    footer-border-variant="white"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end mx-0 w-100"
      >
        <b-button
          variant="light"
          class="my-2 my-sm-0 mr-0 mr-sm-2 bg-gray-550 text-white action-btn"
          @click="cancel"
          pill
        >
          Cancel
        </b-button>
        <b-button
          variant="danger"
          class="my-2 my-sm-0 ml-0 ml-sm-2 action-btn"
          pill
          @click="deleteFn"
        >
          Delete
        </b-button>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <b-icon
        icon="x-circle"
        class="text-white"
        @click="closeModal()"
        aria-label="Close Modal"
      ></b-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100"
      no-gutters
    >
      <p class="text-left text-dark mb-4">
        Are you sure you want to delete this Journal? This action can't be
        undone.
      </p>
    </b-row>
  </b-modal>
</template>

<script>
// services
import { deleteJournals } from "@/services/manualjournal.services";
export default {
  name: "DeleteManualJournalModal",
  props: {
    deleteOptions: {
      type: Object,
    },
    isShow: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    async deleteFn() {
      try {
        let { status } = await deleteJournals(this.deleteOptions.journalId);
        if (status == 200) {
          let payloadNotify = {
            isToast: true,
            title: "Delete Journal",
            content: "The journal have been removed successfully.",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.$emit("reloadFn");
          this.closeModal();
        }
      } catch (error) {
        this.$emit("reloadFn");
        this.closeModal();
        console.log(error);
      }
    },
    closeModal() {
      this.$emit("onClose");
    },
  },
};
</script>
<style scoped>
.action-btn {
  height: 50px;
  min-width: 180px;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
