import axios from "axios";


export const AddFixedAsset = async (payload) => {
    try {
      return await axios.post(`fixed_asset/create`, payload);
    } catch (error) {
      throw new Error(error.response.data.msg)
    }
  };

export const GetAllAssets = async (params) => {
    try {
      return await axios.get("fixed_asset/",{params});
    } catch (error) {
      return error;
    }
  };

  export const GetAssetDetails = async ({ id  }) => {
    
      return await axios.get(`fixed_asset`,{
        params: { id  },
      });
  };
  export const DeleteAsset = async (payload) => {
    try {
      return await axios.delete(`fixed_asset/delete/${payload.id}`);
    } catch (error) {
      return error;
    }
  };

  export const UpdateAsset = async (payload) => {
    try {
      return await axios.put(`fixed_asset/update/${payload.id}`, payload);
    } catch (error) {
      throw new Error(error.response.data.msg)
    }
  };

  export const SearchAssets = ({ search }) => {
    return axios.get(`/fixed_asset/search`, {
      params: { search  },
    });
  };

  export const ExportPDFFixedAssert = async (payload) => {
    try {
      return await axios.get(`/fixed_asset/pdf/fixedAssert`, {
        params: payload,
      });
    } catch (error) {
      return error;
    }
  };

  export const ExportCSVFixedAssert = async (payload) => {
    try {
      return await axios.get(`/fixed_asset/csv/fixedAssert`, {
        params: payload,
      });
    } catch (error) {
      return error;
    }
  };