<template>
    <HomeLayout>
      <b-container
          class="d-flex flex-column align-items-start justify-content-start px-0"
      >
        <!-- table container row -->
        <b-row
          class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
          no-gutters
        >
          <b-card no-body class="shadow-sm border-0 table-container-card w-100">
            <!-- table actions row -->
            <b-row
            class="d-flex flex-row align-items-center justify-content-between w-100 my-1 my-md-2 px-lg-3 py-3"
            no-gutters
          >
            <!-- search input column -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-4 px-lg-0 py-2 py-lg-0"
              cols="12"
              md="4"
            >
              <!-- table search input -->
              <b-input-group class="search-input-group">
                <b-form-input
                  class="search-input border-0 bg-light"
                  type="text"
                  placeholder="Search by reference"
                  v-model="searchTable"
                  @keyup.native.enter="searchFn"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-end justify-content-center mr-2 py-2 py-lg-0"
              cols="12"
              md="4"
            >
              <b-button
                variant="main-green"
                class="d-flex flex-row align-items-center justify-content-center mx-4 mx-md-0 my-2 my-lg-0 px-4 py-2 rounded-lg add-question-btn border-0"
                @click="openAddTransactionModal()"
                pill
                :disabled="getUserRole.role.includes('User')"
              >
                <span class="d-inline ml-2">Create a journal</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 px-2 py-2 py-lg-0 px-3 mb-3 mt-2"
            no-gutters
          >
            <b-col cols="12" lg="3" style="min-width: 330px;">
              <b-dropdown
                id="dropdown-form"
                ref="dropdown"
                variant="light"
                class="w-100 m-0 py-0"
                toggle-class="border border-dark-subtle bg-white rounded-lg border border-primary"
                menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 "
                left
                no-caret
                no-flip
                text="Button text via Prop"
              >
                <template v-slot:button-content>
                  <b-button
                    block
                    id="customer-modal-btn"
                    variant="transparent"
                    class="w-100 d-flex  align-items-center justify-content-between
               rounded-lg p-0 m-0"
                  >
                    <span class="text-primary">{{
                      dataRange ? dataRange : "Date Range"
                    }}</span>
                    <b-icon
                      icon="chevron-down"
                      class="text-primary"
                      font-scale="0.9"
                    ></b-icon>
                  </b-button>
                </template>
                <div style="max-height: 300px; overflow-y: scroll;">
                  <b-dropdown-item
                    @click="() => getDataRange(dataRange)"
                    href="#"
                    v-for="(dataRange, index) in dateRangeOptions"
                    :key="index"
                  >
                    {{ dataRange }}
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </b-col>
            <b-col cols="12" lg="2" style="padding-left: 10px;">
              <b-input-group>
                <b-form-input
                  id="example-input"
                  :value="formatDate(startDate)"
                  type="text"
                  class="border-main-green date-fields-style date-picker  "
                  style="height:40px;"
                  placeholder="From"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    class="date-picker-btn font-primary datepicker align-items-center"
                    v-model="startDate"
                    no-flip
                    size="sm"
                    button-only
                    button-variant="white"
                    locale="en-US"
                    right
                    nav-button-variant="main-green"
                    today-variant="main-green"
                    menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                    aria-controls="start-date-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="12" lg="2">
              <b-input-group>
                <b-form-input
                  id="example-input"
                  :value="formatDate(endDate)"
                  class="border-main-green date-fields-style date-picker"
                  style="height:40px"
                  type="text"
                  placeholder="To"
                  autocomplete="off"
                ></b-form-input>
                <!-- :min="minEndDate" -->
                <b-input-group-append>
                  <b-form-datepicker
                    class="date-picker-btn font-primary datepicker align-items-center"
                    v-model="endDate"
                    no-flip
                    size="sm"
                    button-only
                    button-variant="white"
                    locale="en-US"
                    right
                    nav-button-variant="main-green"
                    today-variant="main-green"
                    menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                    aria-controls="start-date-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 px-2 py-2 py-lg-0 px-2 mb-3 mt-2"
            no-gutters
          >
            <b-col cols="12" lg="2" v-if="is_search">
              <b-button
                class="text-white mx-2 my-2 my-lg-0 py-1 rounded-lg apply-filters-btn "
                pill
                @click="applyFiltersFn"
              >
                <span class="d-inline px-2">Apply</span>
              </b-button>
              <b-link
                @click="clearFilterFn"
                class="text-left font-primary text-main-green font-weight-bold load-more-btn"
                >Clear all
              </b-link>
            </b-col>
          </b-row>

          <!-- table row -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start px-3 w-100 table-container-row"
            no-gutters
          >
            <b-table
              ref="questionsTable"
              :bordered="false"
              :borderless="true"
              :hover="true"
              selectable
              show-empty
              select-mode="single"
              class="table-element"
              thead-class="table-header font-secondary"
              tbody-class="table-body"
              :busy="isLoading"
              :per-page="perPage"
              :fields="customersHeader"
              :items="allJournal"
            >
              <!-- table busy state -->
              <template #table-busy>
                <div class="text-center text-muted my-3">
                  <b-spinner class="align-middle"></b-spinner>
                  <span class="text-left mx-1">Loading...</span>
                </div>
              </template>
              <!-- table empty state -->
              <template #empty="scope">
                <b-row
                  class="d-flex flex-row align-items-center justfiy-content-center py-5"
                  no-gutters
                >
                  <h6 class="text-center mb-0 mx-auto my-5">
                    {{ scope.emptyText }}
                  </h6>
                </b-row>
              </template>
              <!-- Date -->
              <template #cell(Date)="data">
                <b-col
                  class="d-flex flex-column align-items-start justify-content-center px-0 "
                >
                  <span class="main-text">{{ data.value }}</span>
                </b-col>
              </template>
              <!-- Status -->
              <template #cell(Status)="data">
                <b-col
                  class="d-flex flex-column align-items-start justify-content-center px-0"
                >
                  <span
                    class="main-text"
                    :class="data.value === 'Draft' ? 'draft' : 'publish'"
                    >{{ data.value }}</span
                  >
                </b-col>
              </template>
              <!-- Reference -->
              <template #cell(Reference)="data">
                <b-col
                  class="d-flex flex-column align-items-start justify-content-center px-0"
                >
                  <span class="main-text ">{{ data.value }}</span>
                </b-col>
              </template>
              <!-- Description  -->
              <template #cell(Description)="data">
                <b-col
                  class="d-flex flex-column align-items-start justify-content-center px-0"
                >
                  <span class="main-text ">{{ data.value }}</span>
                </b-col>
              </template>

              <!-- actions column -->
              <template #cell(actions)="data">
                <b-dropdown
                  id="dropdown-left"
                  text="Left align"
                  toggle-class="shadow-sm ml-auto border more-btn rounded-circle"
                  no-caret
                  right
                >
                  <template #button-content>
                    <!-- <b-button
                      variant="outline-dark"
                      v-b-tooltip.hover.left
                      title="View More Actions"
                      class="shadow-sm ml-auto border border-prime-gray more-btn"
                      pill
                    > -->
                    <b-icon
                      icon="chevron-down"
                      aria-label="View More Actions"
                      class="mx-auto more-icon"
                      size="lg"
                      font-scale="1.2"
                    ></b-icon>
                    <!-- </b-button> -->
                  </template>
                  <b-dropdown-item-button
                    @click="openEditTransactionModal(data.item.id)"
                                          :disabled="getUserRole.role.includes('User')"
                    >Edit</b-dropdown-item-button
                  >
                  <b-dropdown-item-button
                    @click="viewTransactionModal(data.item.id)"
                                          :disabled="getUserRole.role.includes('User')"
                    >View</b-dropdown-item-button
                  >
                  <b-dropdown-item-button
                  v-if="data.item.Status==='Draft'"
                    @click="openPublishJournalModal(data.item.id)"
                    :disabled="getUserRole.role.includes('User')"
                    >Published</b-dropdown-item-button
                    
                  >
                  <b-dropdown-item-button
                    button-class="text-danger"
                    @click="openDeleteJournalModal(data.item.id, $event.target)"
                    :disabled="getUserRole.role.includes('User')"
                    >Delete</b-dropdown-item-button
                  >
                </b-dropdown>
              </template>
            </b-table>
          </b-row>
          <!-- table controls -->
          <!-- safari fix (flex-shrink-0) -->
          <b-row
            class="d-flex flex-row align-items-center justify-content-center justify-content-md-end flex-shrink-0 px-3 py-2 w-100"
            no-gutters
          >
            <!-- pages per page control -->
            <b-col
              class="d-flex flex-column align-items-center align-items-sm-start align-items-md-end align-items-xl-start justify-content-center py-2 py-md-1"
              cols="12"
              sm="6"
              lg="5"
              xl="2"
            >
              <b-form-group
                label="Per page"
                label-cols="12"
                label-cols-sm="6"
                label-align="left"
                label-align-sm="right"
                label-size="sm"
                label-for="perPageSelect"
                class="mb-0 w-100"
              >
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  class="per-page-select border-1 border-main-green py-0"
                  :options="pageOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <!-- pagination controls -->
            <b-col
              class="d-flex flex-column align-items-center align-items-sm-end justify-content-center py-2 py-md-1"
              cols="12"
              sm="6"
              lg="4"
              xl="5"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="right"
                size="md"
                limit="4"
                pills
                class="table-pagination"
                page-class="custom-page-item"
                ellipsis-class="custom-page-item"
                first-class="custom-page-item"
                last-class="custom-page-item"
                next-class="custom-page-item"
                prev-class="custom-page-item"
              >
                <template #first-text>
                  <b-icon
                    class="text-main-green"
                    icon="chevron-left"
                    size="sm"
                    aria-label="Plus"
                  ></b-icon>
                </template>
                <template #prev-text>
                  <b-icon
                    class="text-main-green"
                    icon="chevron-double-left"
                    size="sm"
                    aria-label="Plus"
                  ></b-icon>
                </template>
                <template #next-text>
                  <b-icon
                    class="text-main-green"
                    icon="chevron-double-right"
                    size="sm"
                    aria-label="Plus"
                  ></b-icon>
                </template>
                <template #last-text>
                  <b-icon
                    class="text-main-green"
                    icon="chevron-right"
                    size="sm"
                    aria-label="Plus"
                  ></b-icon>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <CreateManualJournalModal
        :key="showAddCustomerModalKey"
        @reloadFn="callInitFn"
        @closeFn="closeFn"
        :journal="data"
        :view="is_view"
        :published="published"
      />
      <DeleteManualJournalModal
        :key="isShowDeleteItemRowKey"
        :deleteOptions="deleteOptions"
        @reloadFn="callInitFn"
        @onClose="onCloseDeleteJournalModal"
        :isShow="isShowDeleteItemRow"
      />
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import CreateManualJournalModal from "@/components/Transactions/Modals/CreateManulJournalModal";
import DeleteManualJournalModal from "@/components/Transactions/Modals/DeleteManulJournalModel";
import { getAllJournals } from "@/services/manualjournal.services";
import {mapGetters} from "vuex";
export default {
  name: "Manual Journals",
  components: {
    HomeLayout,
    CreateManualJournalModal,
    DeleteManualJournalModal,
  },
  data() {
    return {
      published:false,
      is_search: false,
      is_view: false,
      isShowDeleteItemRowKey: Number(new Date()) + 100,
      deleteOptions: {},
      rowData: [],
      data: null,
      isShowDeleteItemRow: false,
      showAddCustomerModalKey: Number(new Date()) + 200,
      searchTable: null,
      isLoading: false,
      allJournal: [],
      customersHeader: [
        {
          key: "Date",
          label: "Date",
          sortable: true,
        },
        {
          key: "Status",
          label: "Status",
        },
        {
          key: "Reference",
          label: "Reference",
          sortable: true,
        },
        {
          key: "Description",
          label: "Description",
        },

        {
          key: "actions",
          label: "Actions",
          class: "text-right",
        },
      ],
      dataRange: undefined,
      selectStatus: null,
      startDate: undefined,
      endDate: undefined,
      dateRange: null,
      dateRangeOptions: [
        "This financial year",
        "Last financial year",
        "Last year Q1",
        "Last year Q2",
        "Last year Q3",
        "Last year Q4",
      ],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      };
    },
    watch: {
perPage() {
      this.initFn();
    },
    currentPage() {
      this.initFn();
    },
    searchTable(val) {
      if (val) {
        this.is_search = true;
      } else {
        this.is_search = false;
        this.initFn();
      }
    },
    startDate(val) {
      if (val) {
        this.is_search = true;
      } else {
        this.is_search = false;
      }
    },
    endDate(val) {
      if (val) {
        this.is_search = true;
      } else {
        this.is_search = false;
      }
    },
    dateRange(val) {
      if (val) {
        this.is_search = true;
      } else {
        this.is_search = false;
      }
    },
  },
  async mounted() {
    await this.initFn();
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole"
    }),
  },
  methods: {
    async initFn() {
      console.log(this.dataRange);
      this.isLoading = true;
      const { data } = await getAllJournals({
        limit: this.perPage,
        page: this.currentPage,
        reference: this.searchTable,
        date_range: this.dataRange,
        startDate: this.startDate,
        endDate: this.endDate,
      });
      console.log(data)
      this.totalRows = data.results.total;
      this.rowData = data.results.results;
      const allJournal = data.results.results.map((item) => {
        return {
          Date: this.dateFormat(item.createdDate),
          Status: item.is_published ? "Published" : "Draft",
          Reference: item.reference_number,
          Description: item.description,
          id: item._id,
        };
      });
      this.allJournal = allJournal;
      this.isLoading = false;
    },
    callInitFn() {
      this.initFn();
      this.showAddCustomerModalKey = Number(new Date()) + 200;
      this.isShowDeleteItemRowKey = Number(new Date()) + 100;
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
    dateFormat(date) {
      const dateObj = new Date(date);
      let day = dateObj.getDate();
      let month = dateObj.getMonth()+1;
      const year = dateObj.getFullYear();
      if (day < 10) day = `0${day}`;
      if (month < 10) month = `0${month}`;
      return `${day}/${month}/${year}`
    },
    openAddTransactionModal() {
      console.log("clicked");

      this.$root.$emit("bv::show::modal", `create-journal-modal`);
},
openEditTransactionModal(id) {
      this.data = this.rowData.filter((item) => item._id === id);
      this.$root.$emit("bv::show::modal", `create-journal-modal`);
    },
    openPublishJournalModal(id) {
      this.published=true
      this.data = this.rowData.filter((item) => item._id === id);
      this.$root.$emit("bv::show::modal", `create-journal-modal`);
    },
    viewTransactionModal(id) {
      this.data = this.rowData.filter((item) => item._id === id);
      this.is_view = true;
      this.$root.$emit("bv::show::modal", `create-journal-modal`);
    },
    openDeleteJournalModal(id, button) {
      this.isShowDeleteItemRow = true;
      // this.rowData.filter((item) => item._id === id);
      // const idList = res[0].transactions.map((tr) => tr._id);
      const deleteOptions = {
        journalId: id,
      };

      this.deleteOptions = deleteOptions;
      this.isShowDeleteItemRow = true;
      // this.rowItem = item;
      this.$root.$emit("bv::show::modal", "delete-journal-row-modal", button);
    },
    onCloseDeleteJournalModal() {
      console.log("asdsadsa");
      this.isShowDeleteItemRow = false;
    },
    closeFn() {
      this.is_view = false;
      this.published=false
    },
    getDataRange(val) {
      this.dateRange = val;
      this.dataRange = val;
    },
    applyFiltersFn() {
      this.initFn();
    },
    clearFilterFn() {
      this.clearSearchFilters();
      this.initFn();
    },
    clearSearchFilters() {
      this.is_search = false;
      this.searchTable = null;
      this.startDate = null;
      this.endDate = null;
      this.dateRange = null;
      this.dataRange = undefined;
    },
  },
};
</script>

<style scoped>
.publish {
  color: #0b9b7d;
  font-weight: bold;
}

.draft {
  color: #dc3545;
  font-weight: bold;
}
.types {
  width: 215px !important;
  margin: 0 20px !important;
}
::placeholder {
  color: #0b9b7d;
  opacity: 1;
}
.table-card-row .table-container-card >>> .apply-filters-btn {
  transition: color 200ms ease-in;
  background-color: #0b9b7d;
  border: none;
}

.table-card-row .table-container-card .apply-filters-btn:hover {
  background-color: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
  /* color: var(--primary); */
}

.date-fields-style {
  border: 1.8px solid #0b9b7d;
}

.desWidth {
  width: 80%;
}

.count-style {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  color: #0b9b7d;
  background-color: #f7fbfc;
  font-weight: 500;
  margin-top: -1px;
  padding-left: 10px;
  padding-top: 2px;
}

.disabled-button {
  background-color: #0b9b7d;
  background: rgba(33, 150, 83, 0.5);
  color: #ffffff;
  border-color: #ffffff;
}

.date-picker-btn >>> .btn .b-icon.bi {
  color: #0b9b7d;
}

.date-picker-btn {
  border: 1.8px solid;
  border-color: #0b9b7d;
  border-radius: 0px 3px 3px 0px;
}

.account-dropdown {
  max-height: 240px;
  overflow: auto;
  scrollbar-width: thin;
}

.account-dropdown::-webkit-scrollbar {
  width: 6px;
}

.account-dropdown::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(33, 150, 83, 0.5);
  border-radius: 5px;
}

.account-dropdown::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px #0b9b7d;
}

/* table card */
.table-card-row {
  height: 100%;
}

/* .table-card-row .type-filter {
    width: 100%;
  } */
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 0;
  border: 1px solid var(--light);
}

/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  min-width: 200px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: #0b9b7d;
}

.table-card-row .category-dropdown,
.table-card-row .types-dropdown,
.table-card-row .accounts-dropdown,
.table-card-row .date-picker,
.table-card-row .add-question-btn {
  height: 45px;
  /* width: 100px; */
}

.table-card-row .add-question-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
  color: #ffffff;
  border: none;
}

.table-card-row .accounts-dropdown {
  min-width: 295px;
}

.table-card-row .category-dropdown {
  min-width: 310px;
  padding-left: 15px;
}

.table-card-row .types-dropdown {
  min-width: 310px;
  /* margin-right: 30px; */
}

.table-card-row .startdate-picker {
  width: 200px;
  border: 1.8px solid #0b9b7d;
  color: #0b9b7d;
}

.table-card-row .enddate-picker {
  width: 200px;
  border: 1.8px solid #0b9b7d;
}

/* date picker */
.table-card-row .b-form-datepicker.form-control:focus {
  color: #0b9b7d;
  background-color: #fff;
  border-color: #0b9b7d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 83, 0.5);
}

/* date selector calendar dialog */
.table-card-row
  .b-form-datepicker
  >>> .custom-calendar-dropdown
  .b-calendar-grid.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #0b9b7d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 83, 0.5);
}

.table-card-row .category-dropdown .types-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row .types-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.table-card-row .apply-filters-btn {
  transition: color 200ms ease-in;
  /* color: var(--secondary); */
  /* border: none; */
}

.table-card-row .apply-filters-btn:hover {
  background-color: #0b9b7d;
  /* box-shadow: inset 0px 0px 10px #ffffff; */
  /* color: var(--primary); */
}

.clearAll-text-color {
  color: #0b9b7d;
}

.clearAll-text-color:hover {
  color: #0b9b7d;
}

.vertical-bar {
  margin-right: 19px;
  margin-left: 5px;
  margin-top: 2px;
  color: #bdbdbd;
}

.table-container-row .load-more-btn {
  transition: color 200ms ease-in;
  color: #0b9b7d;
}

.table-container-row .load-more-btn:hover {
  color: #0b9b7d;
  text-decoration: none;
}

/* .table-container-row .table-action-row {
    height: 60px;
    padding-top: 20px;
  } */
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(33, 150, 83, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 1000px;
  width: 100%;
  height: auto;
  scrollbar-width: thin;
}

/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
}

/* .table-element >>> .table-body tr:nth-child(even) {
    vertical-align: middle;
    background-color: var(--light);
  } */
.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: #0b9b7d;
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}

/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  border: hidden;
  /* background-color: var(--white); */
  background-color: transparent;
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  color: #0b9b7d;
  transition: color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .reviewed-icon {
  color: var(--success);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  /* background-color: var(--info); */
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: #0b9b7d;
}

.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}

.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

@media (max-width: 1346px) and (min-width: 1170px) {
  .category-field {
    max-width: 350px;
  }

  .type-field {
    max-width: 200px;
  }
}

@media (max-width: 1170px) and (min-width: 1118px) {
  .category-field {
    max-width: 330px;
  }

  .type-field {
    max-width: 200px;
  }
}

@media (max-width: 1118px) and (min-width: 1060px) {
  .category-field {
    max-width: 310px;
  }

  .type-field {
    max-width: 188px;
  }
}

@media (max-width: 1060px) and (min-width: 992px) {
  .category-field {
    max-width: 270px;
  }

  .type-field {
    max-width: 160px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .table-card-row .category-dropdown {
    min-width: 330px;
  }

  .table-card-row .types-dropdown {
    min-width: 330px;
  }
}
</style>
