<template>
  <!-- send-invoice-modal -->
  <b-modal
      id="add-transaction-modal"
      ref="AddTransactionModal"
      title="Add Transaction"
      size="md"
      header-class="font-primary"
      header-bg-variant="light"
      header-text-variant="main-green"
      header-border-variant="white"
      footer-bg-variant="transparent"
      footer-border-variant="white"
      no-close-on-esc
      no-close-on-backdrop
      @hide="closeModal()"
  >
    <template v-slot:modal-title>
      <template v-if="!isEdit">
        <span class="ml-4 text-main-green">Add Transaction</span>
      </template>
      <template v-else>
        <span class="ml-4 text-main-green">Edit Transaction</span>
      </template>
    </template>
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
          class="d-flex flex-column flex-sm-row align-items-center justify-content-center mx-0 mb-3 w-100"
      >
        <b-button
            v-if="accountTypeOptions.length>0"
            variant="gray-550"
            pill
            class="my-2 my-sm-0 mr-0 mr-sm-2 text-white border-0 bg-gray-550 action-btn"
            @click="cancel"
        >
          Cancel
        </b-button>
        <b-button
            v-if="!isReviewed && accountTypeOptions.length>0"
            variant="gray-550"
            pill
            class="my-2 my-sm-0 mr-0 mr-sm-2 text-main-green border-1 border-main-green bg-white action-btn"
            @click="markAsReviewedFn()"
        >
          <b-icon
              class="mr-1"
              icon="check2"
              size="sm"
              aria-label="Plus"
              font-scale="1.4"
          ></b-icon>
          Mark as reviewed
        </b-button>
        <b-button
            v-if="isReviewed && accountTypeOptions.length>0"
            variant="success"
            pill
            class="my-2 my-sm-0 mr-0 mr-sm-2 text-white"
            disabled
            style="height:45px;"
        >
          <b-icon
              class="mr-1"
              icon="check2"
              size="sm"
              aria-label="Plus"
              font-scale="1.4"
          ></b-icon>
          Reviewed
        </b-button>
        <b-button
            v-if="accountTypeOptions.length>0"
            variant="main-green"
            pill
            class="my-2 my-sm-0 ml-0 border-0 action-btn"
            @click="addTransaction()"
        >
          Save
        </b-button>
        <span
            class="text-left text-dark font-primary font-weight-normal state-text"
            v-if="accountTypeOptions.length<1">To facilitate the addition of a transaction, it's essential to ensure you have a cash and bank account listed in your Chart of Accounts (COA).
          <a href="/charts"> Click here to create an account.</a></span>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <b-icon
          icon="x-circle"
          class="text-dark"
          @click="closeModal()"
          aria-label="Close Modal"
          font-scale="0.8"
      ></b-icon>
    </template>
    <!-- :class="isEdit ? 'mb-0' : 'mb-3 mb-md-2'" -->
    <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100 py-1 mb-3 mb-md-2"
        :class="isEdit ? 'mt-0' : 'mt-3'"
        no-gutters
    >
      <!-- full row Statement date -->
      <b-row
          class="d-flex flex-row align-items-start justify-content-end w-100 px-3 pb-3"
          no-gutters
          v-if="isEdit && this.transactionSource === 'Bankstatement'"
      >
        <span
            class="text-left text-dark font-primary font-weight-normal state-text"
        >
          Source: Statement upload on {{ this.transactionCreatedAt }}
        </span>
      </b-row>


      <b-row class="d-flex flex-row align-items-start justify-content-center w-100 pb-2" no-gutters>
        <b-col cols="5">
          <b-input-group>
            <b-form-input
                id="example-input"
                :value="formatDate(transactionDate)"
                class="border-main-green date-picker"
                type="text"
                placeholder="To"
                autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                  class="date-picker-btn font-primary datepicker align-items-center"
                  v-model="transactionDate"
                  no-flip
                  button-only
                  button-variant="white"
                  size="sm"
                  locale="en-US"
                  right
                  nav-button-variant="main-green"
                  today-variant="main-green"
                  menu-class="rounded-lg custom-calendar-dropdown text-prime-gray"
                  aria-controls="start-date-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
            class="d-flex flex-column align-items-end justify-content-center py-lg-0"
            cols="6"
        >
          <FormInput
              groupId="Total-name-group"
              id="Total-name-group-input"
              class="text-prime-gray rounded-2 pr-0"
              :state="transactionAmountError.status"
              :invalidFeedback="`${transactionAmountError.message}`"
              v-model="transactionAmount"
              label="Total amount"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="number"
          ></FormInput>
        </b-col>
      </b-row>
      <b-row
          class="d-flex flex-row align-items-start justify-content-center w-100"
          no-gutters
      >
        <b-col cols="11">
          <FormSelect
              groupId="fiscalyear1-group"
              id="fiscalyear1-group-input"
              class="text-prime-gray rounded-2"
              :state="transactionCategoryError.status"
              :invalidFeedback="`${transactionCategoryError.message}`"
              label="Category"
              v-model="transactionCategory"
              :whiteBG="false"
              :options="transactionCategoryOptions"
              form="login-form"
          ></FormSelect>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row align-items-start justify-content-center w-100" no-gutters>
        <b-col cols="11">
          <FormInput
              groupId="user-name-group"
              id="user-name-group-input"
              class="text-prime-gray rounded-2"
              :state="transactionDescriptionError.status"
              :invalidFeedback="`${transactionDescriptionError.message}`"
              v-model="transactionDescription"
              label="Description"
              placeholder="Write a Description"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
          ></FormInput>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row align-items-start justify-content-center w-100 pb-2" no-gutters>
        <b-col cols="5">
          <FormSelect
              groupId="transaction-type-group"
              id="transaction-type-group-input"
              class="text-prime-gray rounded-2"
              :state="transactionTypeError.status"
              :invalidFeedback="`${transactionTypeError.message}`"
              label="Income & Expenses"
              v-model="transactionType"
              :whiteBG="false"
              :options="transactionTypeOptions"
              form="login-form"
          ></FormSelect>
        </b-col>
        <b-col cols="6" style="padding-left: 20px">
          <FormSelect
              groupId="fiscalyear-group"
              id="fiscalyear-group-input"
              class="text-prime-gray rounded-2"
              :state="accountTypeError.status"
              :invalidFeedback="`${accountTypeError.message}`"
              label="Account"
              v-model="accountType"
              :whiteBG="false"
              :options="accountTypeOptions"
              form="login-form"
              :searchable="true"
          ></FormSelect>
        </b-col>
      </b-row>

      <b-row class="d-flex flex-row align-items-start justify-content-center w-100 pb-2" no-gutters>
        <b-col cols="5">
          <FormSelect
              groupId="type-group"
              id="type-group-input"
              class="text-prime-gray rounded-2"
              :state="typeError.status"
              :invalidFeedback="`${typeError.message}`"
              label="Type"
              v-model="type"
              :whiteBG="false"
              :options="TypeOptions"
              form="login-form"
              placeholder="Default"
          ></FormSelect>
        </b-col>
        <b-col style="padding-left: 20px" cols="6">
          <FormInput
              groupId="particular-name-group"
              id="particular-name-group-input"
              class="text-prime-gray rounded-2 pr-0"
              :state="particularError.status"
              :invalidFeedback="`${particularError.message}`"
              v-model="particular"
              label="Particular"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
              placeholder="Default"
          ></FormInput>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row align-items-start justify-content-center w-100 pb-2" no-gutters>
        <b-col cols="5">
          <FormInput
              groupId="code-group"
              id="code-group-input"
              class="text-prime-gray rounded-2"
              :state="codeError.status"
              :invalidFeedback="`${codeError.message}`"
              label="Code"
              v-model="code"
              :whiteBG="false"
              form="login-form"
          ></FormInput>
        </b-col>
        <b-col style="padding-left: 20px" cols="6">
          <FormInput
              groupId="reference-name-group"
              id="reference-name-group-input"
              class="text-prime-gray rounded-2 pr-0"
              :state="referenceError.status"
              :invalidFeedback="`${referenceError.message}`"
              v-model="reference"
              label="Reference"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
              placeholder="Default"
          ></FormInput>
        </b-col>
      </b-row>

      <b-row class="d-flex flex-row align-items-start justify-content-end w-100 px-3 mb-2" no-gutters
             v-if="!isShowCustomers && !this.selectedSupplier">
        <b-link @click="showCustomerFn" class="text-left font-primary text-main-green font-weight-normal load-more-btn">
          Add customer
        </b-link>
      </b-row>

      <b-row class="d-flex flex-row align-items-start justify-content-center w-100" no-gutters
             v-if="isShowCustomers || this.selectedCustomer">
        <b-col cols="11">
          <FormSelect
              groupId="fiscalyear-group"
              id="fiscalyear-group-input"
              class="text-prime-gray rounded-2"
              label="Customer"
              v-model="selectedCustomer"
              :whiteBG="false"
              :options="customersList"
              form="login-form"
          ></FormSelect>
        </b-col>
      </b-row>

      <b-row class="d-flex flex-row align-items-start justify-content-end w-100 px-3 mb-2" no-gutters
             v-if="isShowCustomers || this.selectedCustomer">
        <b-link @click="hideCustomerFn" class="text-left font-primary text-main-green font-weight-normal load-more-btn">
          Remove customer
        </b-link>
      </b-row>
      <hr style="width: 89%; text-align: center; margin-left: 1.5em"/>
      <b-row class="d-flex flex-row align-items-start justify-content-end w-100 px-3 mb-2" no-gutters
             v-if="!isShowSuppliers && !this.selectedCustomer">
        <b-link @click="showSupplierFn" class="text-left font-primary text-main-green font-weight-normal load-more-btn">
          Add supplier
        </b-link>
      </b-row>

      <b-row class="d-flex flex-row align-items-start justify-content-center w-100" no-gutters
             v-if="isShowSuppliers || this.selectedSupplier">
        <b-col cols="11">
          <FormSelect
              groupId="fiscalyear-group"
              id="fiscalyear-group-input"
              class="text-prime-gray rounded-2"
              label="Supplier"
              v-model="selectedSupplier"
              :whiteBG="false"
              :options="suppliersList"
              form="login-form"
          ></FormSelect>
        </b-col>
      </b-row>
      <!-- full row input (Customer) -->
      <b-row class="d-flex flex-row align-items-start justify-content-end w-100 px-3 mb-2" no-gutters
             v-if="isShowSuppliers || this.selectedSupplier">
        <b-link @click="hideSupplierFn" class="text-left font-primary text-main-green font-weight-normal load-more-btn">
          Remove Supplier
        </b-link>
      </b-row>
      <hr style="width: 89%; text-align: center; margin-left: 1.5em"/>

      <!-- full row input (Notes) -->
      <b-row class="d-flex flex-row align-items-start justify-content-center w-100 mt-3" no-gutters>
        <b-col cols="11">
          <b-form-textarea
              groupId="user-name-group"
              id="user-name-group-input"
              size="sm"
              placeholder="Add your notes here"
              label="Notes"
              class="text-prime-gray rounded-2"
              v-model="transactionNotes"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
          ></b-form-textarea>
        </b-col>
      </b-row>
      <!-- full row modified date -->
      <b-row class="d-flex flex-row align-items-start justify-content-start w-100 px-3 mt-3" no-gutters v-if="isEdit">
        <span class="text-left text-prime-gray font-primary font-weight-normal modified-text">
          <i>
            Transaction last modified on {{ this.transactionModifiedAt }}
          </i></span
        >
      </b-row>
    </b-row>
  </b-modal>
</template>

<script>
// components
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";

// services
import {
  AddTransaction,
  GetSingleTransaction,
  GetExcludeCAB,
  UpdateTransaction,
  GetCashAndBank,
  MarkAsReviewed,
} from "@/services/transactions.service";
import {GetAllCustomers, GetAllSuppliers} from "@/services/customers.service";

export default {
  name: "AddTransactionModal",
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    itemContent: {
      default: null,
    },
    defaultContent: {
      type: Object,
      default: null,
    },
  },
  components: {
    FormInput,
    FormSelect,
  },
  data() {
    return {
      isReviewed: false,
      isShowCustomers: false,
      isShowSuppliers: false,
      transactionDescription: null,
      accountType: null,
      accountTypeOptions: [],
      transactionCategoryOptions: [],
      selectedCustomer: null,
      selectedSupplier: null,
      endDate: null,
      customersList: [],
      suppliersList: [],
      transactionDate: null,
      transactionType: null,
      transactionTypeOptions: [
        {
          text: "Income",
          value: "income",
        },
        {
          text: "Expense",
          value: "expense",
        },
      ],
      type: 'Default',
      TypeOptions: [
        {
          text: "Default",
          value: "Default",
        },
      ],
      particular: null,
      transactionAmount: null,
      transactionCategory: null,
      transactionNotes: null,
      transactionSource: null,
      transactionCreatedAt: null,
      transactionModifiedAt: null,
      isEdit: false,
      code: null,
      reference: null,
      transactionDescriptionError: {
        status: null,
        message: "",
      },
      accountTypeError: {
        status: null,
        message: "",
      },
      transactionTypeError: {
        status: null,
        message: "",
      },
      transactionAmountError: {
        status: null,
        message: "",
      },
      transactionCategoryError: {
        status: null,
        message: "",
      },
      typeError: {
        status: null,
        message: "",
      },
      particularError: {
        status: null,
        message: "",
      },
      codeError: {
        status: null,
        message: "",
      },
      referenceError: {
        status: null,
        message: "",
      },
      callReview: false,
      tempId: null,
    };
  },
  watch: {
    async itemContent(val) {
      if (val.length === 1) {
        this.isEdit = true;
        await this.initFn();
        await this.initEditFn(val);
      } else {
        this.isEdit = false;
        this.clearFn();
      }
    },
    defaultContent(val) {
      if (val) {
        this.transactionType = val.defaultType;
        this.transactionDate = val.currentDate;
      } else {
        this.transactionType = null;
        this.transactionDate = null;
      }
      this.type = 'Default';
    },
    accountType(value) {
      if (value) {
        this.accountTypeError.status = true;
      } else {
        this.accountTypeError.status = false;
        this.accountTypeError.message = "Please fill out this field";
      }
    },
    transactionAmount(value) {
      if (value && !isNaN(value)) {
        this.transactionAmountError.status = true;
      } else if (!value) {
        this.transactionAmountError.status = false;
        this.transactionAmountError.message = "Please fill out this field!";
      } else if (isNaN(value)) {
        this.transactionAmountError.status = false;
        this.transactionAmountError.message = "Only numbers and decimal values allowed!";
      }
    },
    transactionType(value) {
      if (value) {
        this.transactionTypeError.status = true;
      } else {
        this.transactionTypeError.status = false;
        this.transactionTypeError.message = "Please fill out this field";
      }
    },
    transactionCategory(value) {
      if (value) {
        this.transactionCategoryError.status = true;
      } else {
        this.transactionCategoryError.status = false;
        this.transactionCategoryError.message = "Please fill out this field";
      }
    },
    type(value) {
      if (!value) {
        this.type = 'Default';
      }
    },
  },
  async mounted() {
    await this.initFn();
    this.callReview = false;
  },
  methods: {
    async markAsReviewedFn() {
      this.isReviewed = true;
      if (!this.itemContent[0] && !this.tempId) {
        this.callReview = true;
        await this.addTransaction();
        return;
      }
      const payload = {
        id: !this.itemContent[0] ? this.tempId : this.itemContent[0].id,
        reviewed: true,
      };
      await MarkAsReviewed(payload);
      if (this.callReview) {
        this.callReview = false;
        this.tempId = null;
      }
      this.$emit("reloadFn");
    },
    async initFn() {
      try {
        let {data} = await GetExcludeCAB();
        this.accounts = data.chart.chart;
        let accountsArray = [];
        for (const account in this.accounts) {
          for (const sub in this.accounts[account].sub) {
            const options = [];

            const label = this.accounts[account].sub[sub].title;
            if (label === "Cash and Bank") {
              continue;
            }
            for (const supersub in this.accounts[account].sub[sub].sub) {
              const value = {
                type: this.accounts[account].title,
                subcategory: this.accounts[account].sub[sub].title,
                account: this.accounts[account].sub[sub].sub[supersub].title,
              };

              //Fixed GLcode Undefined
              const glCode = this.accounts[account].sub[sub].sub[supersub].gl_code
              if (glCode === undefined) {
                continue
              }

              options.push({
                value,
                text:
                    this.accounts[account].sub[sub].sub[supersub].title +
                    " [" +
                    this.accounts[account].sub[sub].sub[supersub].gl_code +
                    "]",
              });
            }
            if (options.length > 0)
              accountsArray.push({
                label,
                options,
              });
          }
        }
        this.transactionCategoryOptions = accountsArray;
      } catch (error) {
        console.log(error);
      }
      // get cash and bank
      try {
        let {data} = await GetCashAndBank();
        this.accounts = data.data;
        const subgroups = [
          ...new Set(this.accounts.map((account) => account.subgroup)),
        ];

        const accountTypeOptions = [];
        subgroups.forEach((subgroup) => {
          const subgroupAccounts = this.accounts.filter((account) => account.subgroup === subgroup);

          const optionsArray = [];
          subgroupAccounts.forEach((account) => {
            optionsArray.push({
              value: {
                type: account.group,
                subcategory: account.subgroup,
                account: account.account,
              },
              text: account.account + " [" + account.glcode + "]",
            });
          });

          accountTypeOptions.push({
            label: subgroup,
            options: optionsArray,
          });
        });
        this.accountTypeOptions = accountTypeOptions;
      } catch (error) {
        console.log(error);
      }

      try {
        let {data} = await GetAllCustomers();
        this.customersList = data.results.results.map((x) => {
          return {
            text: x.name,
            value: x._id,
          };
        });
      } catch (error) {
        console.log(error);
      }

      try {
        let {data} = await GetAllSuppliers();
        this.suppliersList = data.results.results.map((x) => {
          return {
            text: x.name,
            value: x._id,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    async initEditFn(val) {
      try {
        let {data, status} = await GetSingleTransaction(val[0].id);
        if (status === 200) {
          this.transactionDescription = data.description;
          const options = [];
          const value = {
            type: data.other_accounts[0]?.split(",")[0],
            subcategory: data.other_accounts[0]?.split(",")[1],
            account: data.other_accounts[0]?.split(",")[2],
          };
          const text = data.other_accounts[0]?.split(",")[2];
          options.push({value, text});
          this.accountType = options[0].value;
          const options2 = [];
          const value2 = {
            type: data.other_accounts[0]?.split(",")[0],
            subcategory: data.other_accounts[0]?.split(",")[1],
            account: data.other_accounts[0]?.split(",")[2],
          };
          const text2 = data.other_accounts[0]?.split(",")[2];
          options2.push({value2, text2});
          this.transactionDate = data.date.split("T")[0];
          this.transactionAmount = data.total_amount.toFixed(2);
          this.transactionType = data.type;
          this.selectedCustomer = data.customer;
          this.selectedSupplier = data.supplier;
          this.transactionNotes = data.notes;
          this.transactionSource = data.source;
          this.particular = data.particular;
          this.code = data.code;
          this.reference = data.reference;
          this.transactionCreatedAt = data.created_at.split("T")[0];
          this.transactionModifiedAt = data.updated_at.split("T")[0];
          if(data.account_type===this.accountTypeOptions[0].label){
            this.accountType.type = data.account_group;
            this.accountType.subcategory = data.account_type;
            this.accountType.account = data.account_name;
          }else {
            this.accountType =null
          }  
          this.isReviewed = data.reviewed;
          if (data.other_accounts) {
            let foundCategory = false;
            for (let i = 0; i < this.transactionCategoryOptions.length; i++) {
              for (let j = 0; j < this.transactionCategoryOptions[i].options.length; j++) {
                if (this.transactionCategoryOptions[i].options[j].value.type.toLowerCase() === data.other_accounts[0].split(",")[0].toLowerCase()
                    && this.transactionCategoryOptions[i].options[j].value.subcategory.toLowerCase() === data.other_accounts[0].split(",")[1].toLowerCase()
                    && this.transactionCategoryOptions[i].options[j].value.account.toLowerCase() === data.other_accounts[0].split(",")[2].toLowerCase()) {
                  this.transactionCategory = this.transactionCategoryOptions[i].options[j].value;
                  foundCategory = true;
                  break
                }
                if (foundCategory) break;
              }
              if (foundCategory) break;
            }
            if (!foundCategory) {
              this.transactionCategory.type = data.other_accounts[0].split(",")[0];
              this.transactionCategory.subcategory = data.other_accounts[0].split(",")[1];
              this.transactionCategory.account = data.other_accounts[0].split(",")[2];
            }
          } else {
            this.transactionCategory.type = this.getDefaultCate().split(",")[0];
            this.transactionCategory.subcategory = this.getDefaultCate().split(",")[1];
            this.transactionCategory.account = this.getDefaultCate().split(",")[2];
          }
        } else {
          console.log("server error");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async addTransaction() {
      if (this.validateForm()) {
        if (this.isEdit) {
          let payload = {
            id: this.itemContent[0].id,
            description: this.transactionDescription,
            account: `${this.accountType.type},${this.accountType.subcategory},${this.accountType.account}`,
            category:
                this.transactionCategory !== null
                    ? `${this.transactionCategory.type},${this.transactionCategory.subcategory},${this.transactionCategory.account}`
                    : this.getDefaultCate(),
            account_id: null,
            customer: this.selectedCustomer,
            supplier: this.selectedSupplier,
            date: this.transactionDate,
            type: this.transactionType.toLowerCase(),
            total_amount: this.transactionAmount,
            notes: this.transactionNotes,
            particular: this.particular,
            code: this.code,
            reference: this.reference,
          };
          try {
            let {status} = await UpdateTransaction(payload);
            if (status === 200) {
              let payloadNotify = {
              isToast: true,
              title: "Transaction",
              content: "Transaction updated successfully",
              variant: "success",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
              this.$emit("reloadFn");
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          if (this.transactionAmount) {
            let payload = {
              description: this.transactionDescription,
              account: `${this.accountType.type},${this.accountType.subcategory},${this.accountType.account}`,
              category:
                  this.transactionCategory !== null
                      ? `${this.transactionCategory.type},${this.transactionCategory.subcategory},${this.transactionCategory.account}`
                      : this.getDefaultCate(),
              account_id: null,
              customer: this.selectedCustomer,
              supplier: this.selectedSupplier,
              date: this.transactionDate,
              type: this.transactionType.toLowerCase(),
              total_amount: this.transactionAmount,
              notes: this.transactionNotes,
              particular: this.particular,
              code: this.code,
              reference: this.reference,
            };
            try {
              let {data, status} = await AddTransaction(payload);
              if (status === 200) {
                if (this.callReview) {
                  this.tempId = data.transaction[0]._id;
                  await this.markAsReviewedFn();
                }
                this.$emit("reloadFn");
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    },
    getDefaultCate() {
      if (this.transactionType === "expense") {
        return "Expenses,Uncategorized Expense,Uncategorized Expense";
      } else {
        return "Income,Uncategorized Income,Uncategorized income";
      }
    },
    validateForm() {
      let validated = true;
      if (!this.accountType) {
        this.accountTypeError.status = false;
        this.accountTypeError.message = "Please fill out this field";
        validated = false;
      }
      if (!this.transactionType) {
        this.transactionTypeError.status = false;
        this.transactionTypeError.message = "Please fill out this field";
        validated = false;
      }
      if (!this.transactionAmount) {
        this.transactionAmountError.status = false;
        this.transactionAmountError.message = "Please fill out this field!";
        validated = false;
      } else if (isNaN(this.transactionAmount)) {
        this.transactionAmountError.status = false;
        this.transactionAmountError.message = "Only numbers and decimal values allowed!";
        validated = false;
      }
      if (!this.transactionCategory) {
        this.transactionCategoryError.status = false;
        this.transactionCategoryError.message = "Please fill out this field";
        validated = false;
      }
      return validated;
    },
    showCustomerFn() {
      this.isShowCustomers = true;
      this.isShowSuppliers = false;
    },
    hideCustomerFn() {
      this.isShowCustomers = false;
      this.selectedCustomer = null;
    },
    showSupplierFn() {
      this.isShowSuppliers = true;
      this.isShowCustomers = false;
    },
    hideSupplierFn() {
      this.isShowSuppliers = false;
      this.selectedSupplier = null;
    },
    clearFn() {
      ({
        transactionDescription: this.transactionDescription,
        transactionDate: this.transactionDate,
        transactionAmount: this.transactionAmount,
        transactionType: this.transactionType,
        transactionCategory: this.transactionCategory,
        accountType: this.accountType,
        transactionNotes: this.transactionNotes,
        type: this.type,
        particular: this.particular,
        code: this.code,
        reference: this.reference,
      } = {});
    },
    closeModal() {
      this.$emit("onClose");
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    }
  },
};
</script>

<style scoped>
.date-picker-btn >>> .btn .b-icon.bi {
  color: #0b9b7d;
}

.date-picker-btn {
  border: 1.8px solid;
  border-color: #0b9b7d;
  border-radius: 0px 3px 3px 0px;
}

.state-text {
  font-size: 0.8rem;
}

.modified-text {
  font-size: 0.9rem;
}

.action-btn {
  height: 45px;
  min-width: 110px;
}

.action-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
  color: #ffffff;
}

.load-more-btn:hover {
  text-decoration: none;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
