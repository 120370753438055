<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
        no-gutters
      >
        <b-card no-body class="shadow-sm border-0 table-container-card w-100">
          <!-- table actions row -->
          <b-row
            class="d-flex flex-row align-items-center justify-content-start w-100 mt-1 mt-md-2 px-3 pt-3"
            no-gutters
          >
            <!-- account dropdown column -->
            <b-col cols="12" lg="4">
              <b-dropdown
                id="dropdown-form"
                ref="dropdown"
                variant="light"
                class="w-100 py-0 m-0"
                toggle-class="border border-dark-subtle bg-white rounded-lg "
                menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 filter-dropdown-menu"
                left
                no-caret
                no-flip
              >
                <template v-slot:button-content>
                  <b-button
                    block
                    id="customer-modal-btn"
                    variant="transparent"
                    class="w-100 d-flex align-items-center justify-content-between rounded-lg py-0 px-0"
                    :class="selectedAccount ? 'text-dark' : '#222831'"
                  >
                    {{
                      selectedAccount ? selectedAccount.account : " Accounts "
                    }}
                    <div>
                      <span
                        class="text-right mr-3 text-success"
                        v-if="!displayTotal.includes('-')"
                        >{{ anchorCurrency }} {{ displayTotal }}</span
                      >
                      <span
                        class="text-right mr-3 text-danger"
                        v-if="displayTotal.includes('-')"
                        >{{ anchorCurrency }} {{ displayTotal }}</span
                      >
                      <b-icon icon="chevron-down" font-scale="0.9"></b-icon>
                    </div>
                  </b-button>
                </template>
                <b-dropdown-group flush class="rounded-lg py-0">
                  <b-row
                    v-if="
                      accountOptions &&
                      accountOptions.options &&
                      accountOptions.options.length >= 0 &&
                      accountOptions.options[0].options.length >= 0
                    "
                    class="w-100 d-flex flex-row align-items-center justify-content-start px-3 py-3"
                    no-gutters
                  >
                    <b-col class="">
                      <!-- table search input -->
                      <b-input-group class="search-input-group w-100">
                        <b-form-input
                          class="search-input border-0 bg-light"
                          type="text"
                          placeholder="Search Account"
                          v-model="searchAccountText"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-row
                            class="d-flex flex-row align-items-center justify-content-center px-3 border-0 rounded-right bg-light w-100"
                            no-gutters
                          >
                            <b-icon
                              class="text-main-green"
                              icon="search"
                              size="lg"
                              aria-label="Search"
                            ></b-icon>
                          </b-row>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <div class="w-100 account-dropdown">
                    <template v-for="account in computedAccountOptions">
                      <b-row
                        :key="account.label"
                        class="w-100 d-flex flex-row align-items-center justify-content-start"
                        no-gutters
                      >
                        <b-col
                          class="w-100 d-flex flex-column align-items-start justify-content-center px-3"
                        >
                          <span
                            class="pl-3 py-2 font-weight-bold text-dark"
                            style="font-size: 14px"
                            >{{ account.label }}</span
                          >
                          <b-col class="px-2">
                            <span
                              v-if="account.options.length === 0"
                              class="main-text"
                              style="font-size: 16px"
                              >No search results</span
                            >

                            <template v-for="sub_account in account.options">
                              <b-dropdown-item-button
                                button-class="py-0 px-0 my-0 mx-0"
                                class="w-100 rounded"
                                :key="sub_account.id"
                                @click="selectedAccount = sub_account.value"
                              >
                                <b-col
                                  class="w-100 px-3 py-2 d-flex flex-column align-items-start justify-content-center rounded"
                                  :class="
                                    selectedAccount &&
                                    selectedAccount.id ===
                                      sub_account.value.id &&
                                    'bg-main-green text-white'
                                  "
                                >
                                  <span
                                    class="main-text"
                                    style="font-size: 16px; width: 100%"
                                    >{{ sub_account.text }}
                                    <span
                                      class="text-right mr-3 text-success"
                                      style="float: right"
                                      :class="
                                        selectedAccount &&
                                        selectedAccount.id ===
                                          sub_account.value.id &&
                                        'bg-main-green text-white'
                                      "
                                      v-if="
                                        !computeAccountTotal(
                                          sub_account.value.id
                                        ).includes('-')
                                      "
                                    >
                                      {{
                                        computeAccountTotal(
                                          sub_account.value.id
                                        )
                                      }}
                                    </span>
                                    <span
                                      class="text-right mr-3 text-danger"
                                      style="float: right"
                                      v-else
                                      :class="
                                        selectedAccount &&
                                        selectedAccount.id ===
                                          sub_account.value.id &&
                                        'bg-main-green text-white'
                                      "
                                    >
                                      {{
                                        computeAccountTotal(
                                          sub_account.value.id
                                        )
                                      }}
                                    </span>
                                  </span>
                                </b-col>
                              </b-dropdown-item-button>
                              <hr
                                :key="sub_account.id + 1"
                                class="border-bottom my-1 border-gray-500 w-100"
                              />
                            </template>
                          </b-col>
                        </b-col>
                      </b-row>
                    </template>
                  </div>
                  <b-dropdown-item-button
                    button-class="px-4 py-3 text-main-green"
                    @click="openAddAccountModal($event.target)"
                    :disabled="getUserRole.role.includes('User')"
                  >
                    <b-icon
                      class="text-main-green mr-2"
                      icon="plus-circle-fill"
                      size="sm"
                      aria-label="Plus"
                      font-scale="1.1"
                    ></b-icon>
                    Add a new account
                  </b-dropdown-item-button>
                </b-dropdown-group>
              </b-dropdown>
            </b-col>
            <b-col cols="12" lg="8">
              <b-row
                class="d-flex flex-row align-items-start justify-content-center justify-content-md-center justify-content-lg-end w-100 mx-0"
                no-gutters
              >
                <b-button
                  variant="main-green"
                  class="d-flex flex-row align-items-center justify-content-center mr-2 my-2 my-lg-0 py-2 border-0 rounded-lg add-question-btn"
                  @click="openAddTransactionModal($event.target, 'income')"
                  pill
                  :disabled="
                    this.isLoading ||
                    this.selected.length !== 0 ||
                    getUserRole.role.includes('User')
                  "
                >
                  <span class="d-inline mx-1 py-1" v-if="!this.isLoading"
                    >Add income</span
                  >
                  <span v-else>Loading...</span>
                </b-button>
                <b-button
                  variant="main-green"
                  class="d-flex flex-row align-items-center justify-content-center mr-2 my-2 my-lg-0 py-2 border-0 rounded-lg add-question-btn"
                  @click="openAddTransactionModal($event.target, 'expense')"
                  pill
                  :disabled="
                    this.isLoading ||
                    this.selected.length !== 0 ||
                    getUserRole.role.includes('User')
                  "
                >
                  <span class="d-inline mx-1 py-1" v-if="!this.isLoading"
                    >Add expense</span
                  >
                  <span v-else>Loading...</span>
                </b-button>
                <b-button
                  variant="outline-main-green"
                  class="d-flex flex-row align-items-center justify-content-center mx-0 my-2 my-lg-0 py-2 border border-main-green rounded-lg add-question-btn"
                  pill
                  to="/transactions/upload_bank_statement"
                  :disabled="
                    this.isLoading || getUserRole.role.includes('User')
                  "
                >
                  <span class="d-inline mx-1 py-1" v-if="!this.isLoading"
                    >Upload a bank statement</span
                  >
                  <span v-else>Loading...</span>
                </b-button>
              </b-row>
            </b-col>
          </b-row>
          <!-- Filters Count -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 my-0 py-0 px-3 mt-5 mb-2"
            no-gutters
          >
            <span class="ml-2 count-style">
              {{ computedfilterCount }}
            </span>
            <span class="ml-2 text-muted">Filters</span>
          </b-row>
          <!-- date filters row  -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 my-0 py-0 px-3 mt-3"
            no-gutters
          >
            <!-- filter column -->
            <b-col cols="12" lg="5">
              <b-dropdown
                id="dropdown-form"
                ref="dropdown"
                variant="light"
                class="w-100 m-0 py-0"
                toggle-class="border border-dark-subtle bg-white rounded-lg border border-primary "
                menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 "
                left
                no-caret
                no-flip
                text="Button text via Prop"
              >
                <template v-slot:button-content>
                  <b-button
                    block
                    id="customer-modal-btn"
                    variant="transparent"
                    class="w-100 d-flex align-items-center justify-content-between rounded-lg p-0 m-0"
                  >
                    <span class="text-primary">{{
                      selectAccountCat ? selectAccountCat : "All Categories"
                    }}</span>
                    <b-icon
                      icon="chevron-down"
                      class="text-primary"
                      font-scale="0.9"
                    ></b-icon>
                  </b-button>
                </template>
                <div style="max-height: 300px; overflow-y: scroll">
                  <ul
                    style="list-style: none; padding-left: 10px"
                    v-for="(item, index) in accountCategoryOptions"
                    :key="index"
                  >
                    <li>
                      <h6 class="font-weight-bold p-0 m-0">
                        {{ item.label }}
                      </h6>

                      <p
                        v-for="(category, i) in item.options"
                        @click="() => getAccount(category)"
                        style="cursor: pointer"
                        :key="i"
                        class="px-4 py-0 m-0 dropdown-item"
                      >
                        <b-dropdown-item>
                          {{ category.text }}
                        </b-dropdown-item>
                      </p>
                    </li>
                  </ul>
                </div>
              </b-dropdown>
            </b-col>
            <!-- filter dropdown -->
            <b-col cols="12" lg="3">
              <b-dropdown
                id="dropdown-form"
                ref="dropdown"
                variant="light"
                class="w-100 m-0 py-0 types"
                toggle-class="border border-dark-subtle bg-white rounded-lg border border-primary "
                menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 "
                left
                no-caret
                no-flip
                text="Button text via Prop"
              >
                <template v-slot:button-content>
                  <b-button
                    block
                    id="customer-modal-btn"
                    variant="transparent"
                    class="w-100 d-flex align-items-center justify-content-between rounded-lg p-0 m-0"
                  >
                    <span class="text-primary">{{
                      selectedAccountType ? selectedAccountType : "All Types"
                    }}</span>
                    <b-icon
                      icon="chevron-down"
                      class="text-primary"
                      font-scale="0.9"
                    ></b-icon>
                  </b-button>
                </template>
                <b-dropdown-item
                  @click="() => getAccountTypes(selectedAccountType)"
                  href="#"
                  v-for="(selectedAccountType, index) in accountTypeOptions"
                  :key="index"
                >
                  {{ selectedAccountType.text }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>

            <b-col cols="12" lg="4" class="ml-0">
              <!-- table search input -->
              <b-input-group class="search-input-group w-100">
                <b-form-input
                  class="search-input border-0 bg-light"
                  type="text"
                  placeholder="Search by description"
                  v-model="searchTable"
                  @keyup.native.enter="searchFn"
                ></b-form-input>
                <b-input-group-append>
                  <b-row
                    class="d-flex flex-row align-items-center justify-content-center px-3 border-0 rounded-right bg-light w-100"
                    no-gutters
                  >
                    <b-icon
                      class="text-main-green"
                      icon="search"
                      size="lg"
                      aria-label="Search"
                    ></b-icon>
                  </b-row>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <!-- search row  -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 px-2 py-2 py-lg-0 px-3 mb-3 mt-2"
            no-gutters
          >
            <b-col cols="12" lg="3" style="min-width: 325px">
              <b-dropdown
                id="dropdown-form"
                ref="dropdown"
                variant="light"
                class="w-100 m-0 py-0"
                toggle-class="border border-dark-subtle bg-white rounded-lg border border-primary"
                menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 "
                left
                no-caret
                no-flip
                text="Button text via Prop"
              >
                <template v-slot:button-content>
                  <b-button
                    block
                    id="customer-modal-btn"
                    variant="transparent"
                    class="w-100 d-flex align-items-center justify-content-between rounded-lg p-0 m-0"
                  >
                    <span class="text-primary">{{
                      dateRange ? dateRange : "Data Range"
                    }}</span>
                    <b-icon
                      icon="chevron-down"
                      class="text-primary"
                      font-scale="0.9"
                    ></b-icon>
                  </b-button>
                </template>
                <div style="max-height: 300px; overflow-y: scroll">
                  <b-dropdown-item
                    @click="() => getDataRange(dateRange)"
                    href="#"
                    v-for="(dateRange, index) in dateRangeOptions"
                    :key="index"
                  >
                    {{ dateRange }}
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </b-col>
            <b-col cols="12" lg="2">
              <b-input-group>
                <b-form-input
                  id="example-input"
                  :value="formatDate(startDate)"
                  type="text"
                  class="border-main-green date-fields-style date-picker"
                  style="height: 40px; margin-left: 5px"
                  placeholder="From"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    class="date-picker-btn font-primary datepicker align-items-center"
                    v-model="startDate"
                    no-flip
                    size="sm"
                    button-only
                    button-variant="white"
                    locale="en-US"
                    right
                    nav-button-variant="main-green"
                    today-variant="main-green"
                    menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                    aria-controls="start-date-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="12" lg="2">
              <b-input-group>
                <b-form-input
                  id="example-input"
                  :value="formatDate(endDate)"
                  class="border-main-green date-fields-style date-picker"
                  style="height: 40px; margin-left: 5px"
                  type="text"
                  placeholder="To"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    class="date-picker-btn font-primary datepicker align-items-center"
                    v-model="endDate"
                    :min="minEndDate"
                    no-flip
                    size="sm"
                    button-only
                    button-variant="white"
                    locale="en-US"
                    right
                    nav-button-variant="main-green"
                    today-variant="main-green"
                    menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                    aria-controls="start-date-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col
              cols="12"
              lg="4"
              class="d-flex flex-column align-items-center align-items-lg-end justify-content-center pr-2 pt-4 pt-lg-0"
              style="margin-left: -138px"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-start"
                no-gutters
                v-if="computedFilterValidations"
              >
                <b-button
                  variant="main-green"
                  class="text-white mx-2 my-2 my-lg-0 py-1 rounded-lg apply-filters-btn"
                  pill
                  @click="applyFiltersFn"
                >
                  <span class="d-inline px-2">Apply</span>
                </b-button>
                <div class="vertical-bar">|</div>
                <b-link
                  @click="clearFilterFn"
                  class="text-left font-primary clearAll-text-color font-weight-bold load-more-btn"
                  style="margin-top: 2px"
                  >Clear all
                </b-link>
              </b-row>
            </b-col>
          </b-row>
          <!-- table row -->
          <b-row
            class="d-flex flex-row align-items-center justify-content-start px-3 mt-4 w-100 table-container-row"
            no-gutters
          >
            <b-row
              class="d-flex flex-row align-items-start justify-content-start my-1 px-3 pt-3 w-100 bg-light table-action-row"
              no-gutters
            >
              <b-col
                class="d-flex flex-column align-items-start justify-content-start edit-delete-row"
                cols="2"
              >
                <b-col>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="selectStatus"
                    name="checkbox-1"
                    value="selectAll"
                    @change="selectAllRows"
                    unchecked-value="unselectAll"
                  >
                    <span
                      class="d-inline mx-2 pb-3"
                      v-if="this.selectStatus === 'selectAll'"
                      >{{ this.selected.length }} Selected</span
                    >
                    <span class="d-inline mx-2 pb-3" v-else>Select All</span>
                  </b-form-checkbox>
                </b-col>
              </b-col>
              <b-col
                class="d-flex flex-column align-items-start align-items-md-start justify-content-start"
              >
                <b-row v-if="this.selected.length >= 1" class="px-3 pb-3">
                  <b-button
                    v-if="selected.length === 1"
                    variant="main-green"
                    size="sm"
                    class="mr-3 apply-filters-btn"
                    pill
                    :disabled="
                      this.selected.length < 1 ||
                      this.selected.length > 1 ||
                      getUserRole.role.includes('User')
                    "
                    :class="
                      selected.length > 1
                        ? 'disabled-button'
                        : 'apply-filters-btn'
                    "
                    @click="openAddTransactionModal($event.target)"
                    ><span class="d-inline mx-2">Edit</span>
                  </b-button>
                  <b-row class="px-3">
                    <div class="position-relative">
                      <b-button
                        v-b-modal.modal-cat-sm
                        v-if="selected.length > 1"
                        @click="show = true"
                        variant="main-green"
                        class="d-flex flex-row align-items-center justify-content-center mr-2 my-2 my-lg-0 px-4 py-2 border-0 rounded-lg"
                      >
                        Edit Category
                      </b-button>
                    </div>
                  </b-row>

                  <div class="vertical-bar">|</div>
                  <b-link
                    class="text-left text-danger font-primary font-weight-bold"
                    style="margin-top: 3px"
                    :disabled="
                      this.selected.length < 1 ||
                      getUserRole.role.includes('User')
                    "
                    @click="openDeleteTransactionModal($event.target)"
                    >Delete
                  </b-link>
                </b-row>
              </b-col>
            </b-row>

            <b-col>
              <b-table
                ref="selectableTable"
                table
                :bordered="false"
                :hover="true"
                head-variant="light"
                selectable
                show-empty
                select-mode="multi"
                class="table-element"
                thead-class="table-header font-secondary"
                tbody-class="table-body"
                tdClass="text-primary"
                :busy="isLoading"
                :fields="transactionsHeader"
                :items="transactionsItems"
                @row-selected="onRowSelected"
              >
                <!-- table busy state -->
                <template #table-busy>
                  <div class="text-center text-muted my-3">
                    <b-spinner class="align-middle"></b-spinner>
                    <span class="text-left mx-1">Loading...</span>
                  </div>
                </template>
                <!-- table empty state -->
                <template #empty="scope">
                  <b-row
                    class="d-flex flex-row align-items-center justfiy-content-center py-5"
                    no-gutters
                  >
                    <h6 class="text-center mb-0 mx-auto my-5">
                      {{ scope.emptyText }}
                    </h6>
                  </b-row>
                </template>
                <!-- select row  -->
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true">
                      <b-icon
                        class="text-main-green"
                        icon="check-square-fill"
                      ></b-icon>
                    </span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">
                      <b-icon icon="square"></b-icon>
                    </span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
                <template #cell(isBasic)="data">
                  <b-icon
                    icon="star"
                    :class="data.value ? 'text-gray-550' : 'text-warning'"
                  ></b-icon>
                </template>
                <!-- transaction Date  -->
                <template #cell(transactionDate)="data">
                  <span class="main-text">{{ data.value }}</span>
                </template>
                <!-- transaction Description -->
                <template #cell(transactionDescription)="data">
                  <span class="main-text mb-2">{{ data.value }}</span>
                </template>

                <!-- transaction Amount  -->
                <template #cell(transactionAmount)="data">
                  <template v-if="data.value.type === 'income'">
                    <span class="main-text mb-2 text-success"
                      >{{ currency }}{{ numberFormat(data.value.amount) }}
                    </span>
                  </template>
                  <template v-if="data.value.type === 'expense'">
                    <span class="main-text mb-2 text-danger"
                      >{{ currency }} {{ numberFormat(data.value.amount) }}
                    </span>
                  </template>
                  <template v-if="data.value.type === undefined">
                    <span class="main-text mb-2 text-dark"
                      >{{ currency }} {{ numberFormat(data.value.amount) }}
                    </span>
                  </template>
                </template>
                <!-- transaction Category  -->
                <template
                  #cell(transactionCategory)="data"
                  style="background-color: white"
                >
                  <span v-if="!data.rowSelected" class="main-text mb-2">{{
                    data.value
                  }}</span>
                  <div>
                    <b-button
                      v-b-modal.modal-cat-sm
                      v-if="data.rowSelected"
                      @click="show = true"
                      variant="main-green"
                      class="d-flex flex-row align-items-center justify-content-center mr-2 my-2 my-lg-0 px-4 py-2 border-0 rounded-lg"
                    >
                      Edit Category
                    </b-button>
                  </div>
                </template>
                <!-- actions column -->
                <template #cell(actions)="data">
                  <!-- review button -->
                  <b-button
                    v-b-tooltip.hover.left
                    :title="
                      data.value.isReviewed
                        ? 'Mark as not reviewed'
                        : 'Mark as reviewed'
                    "
                    class="shadow-none mx-1 edit-btn"
                    pill
                    @click="
                      markAsReviewedFn(data.value.id, data.value.isReviewed)
                    "
                    :disabled="getUserRole.role.includes('User')"
                  >
                    <b-icon
                      v-if="data.value.isReviewed"
                      icon="check-circle-fill"
                      aria-label="Mark as not reviewed"
                      class="reviewed-icon"
                    ></b-icon>
                    <b-icon
                      v-else
                      icon="check2"
                      aria-label="Mark as reviewed"
                      class="edit-icon text-dark"
                    ></b-icon>
                  </b-button>
                </template>
              </b-table>
            </b-col>
            <b-row
              class="d-flex flex-row align-items-center justify-content-center my-1 px-3 py-3 w-100"
              no-gutters
            >
              <b-link
                @click="loadMoreFn"
                class="text-left text-color font-weight-bold load-more-btn"
                v-if="isShowLoadMore && !isLoading"
                >Load More
              </b-link>
              <h6
                class="text-left text-gray-750 font-primary font-weight-normal"
                v-else
              >
                There are no more records to show
              </h6>
            </b-row>
          </b-row>
        </b-card>
        <b-modal
          v-model="show"
          id="modal-cat-sm"
          :centered="true"
          size="lg"
          title="Choose Category"
          hide-footer
        >
          <b-row class="w-100 d-flex flex-row align-items-center" no-gutters>
            <b-col cols="12">
              <!-- table search input -->
              <b-input-group class="CategorySearch w-100">
                <b-form-input
                  class="categorySearch-input border-0 bg-light"
                  type="text"
                  placeholder="Search by category"
                  v-model="searchTableCategory"
                  @keyup.native.enter="searchFnCategory"
                ></b-form-input>
                <b-input-group-append>
                  <b-row
                    class="d-flex flex-row align-items-center justify-content-center px-3 border-0 rounded-right bg-light w-100"
                    no-gutters
                  >
                    <b-icon
                      class="text-main-green"
                      icon="search"
                      size="lg"
                      aria-label="Search"
                    ></b-icon>
                  </b-row>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <div class="w-100 account-dropdown">
            <template
              v-for="(account, index) in filteredAccountCategoryOptions"
            >
              <b-row
                :key="index"
                class="w-100 d-flex flex-row align-items-center justify-content-start"
                no-gutters
              >
                <b-col
                  class="w-100 d-flex flex-column align-items-start justify-content-center px-3"
                >
                  <span
                    class="pl-3 py-2 font-weight-bold text-dark"
                    style="font-size: 20px"
                    >{{ account.label }}</span
                  >
                  <b-col class="px-2">
                    <template v-for="sub_account in account.options">
                      <b-dropdown-item-button
                        button-class="py-0 px-0 my-0 mx-0"
                        class="w-100 rounded list-unstyled"
                        :key="sub_account.id"
                        @click="changeTransactionCategory(sub_account, data)"
                      >
                        <b-col
                          class="w-100 px-3 py-2 d-flex flex-column align-items-start justify-content-center rounded"
                          :class="
                            selectedAccount &&
                            selectedAccount.id === sub_account.value.id &&
                            'bg-main-green text-white'
                          "
                        >
                          <span class="main-text" style="font-size: 16px">{{
                            sub_account.text
                          }}</span>
                        </b-col>
                      </b-dropdown-item-button>
                      <hr
                        :key="sub_account.text"
                        class="border-bottom my-1 border-gray-500 w-100"
                      />
                    </template>
                  </b-col>
                </b-col>
              </b-row>
            </template>
          </div>
        </b-modal>
        <AddTransactionModal
          :itemContent="rowItem"
          :defaultContent="defaultContent"
          @reloadFn="callInitFn"
          :key="showAddTransactionModalKey"
          @onClose="onCloseAddTransactionModal"
        />
        <DeleteTransactionModal
          :itemContent="rowItem"
          :key="showDeleteTransactionModalKey"
          @reloadFn="callInitFn"
          @onClose="onCloseDeleteTransactionModal"
        />
        <AddAccountModal
          :key="showAddAccountModalKey"
          :accounts="allAccounts"
          :AccountType="this.AccountType"
          @reloadFn="callInitFn"
        />
      </b-row>
    </b-container>
  </HomeLayout>
</template>

<script>
import HomeLayout from "@/layout/HomeLayout";
import AddTransactionModal from "@/components/Transactions/Modals/AddTransactionModal";
import DeleteTransactionModal from "@/components/Transactions/Modals/DeleteTransactionModal";
import AddAccountModal from "@/components/ChartOfAccounts/modals/AddAccountModal";

import {
  GetAccountTotals,
  GetAllChartOfAccounts,
  GetAllFilters,
  GetCashAndBank,
  GetExcludeCAB,
  SearchTransactions,
  UpdateTransaction,
} from "@/services/transactions.service";
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";
import { formatNumbers } from "@/util/formatNumbers.js";
import { mapGetters } from "vuex";

export default {
  name: "Transactions",
  components: {
    HomeLayout,
    AddTransactionModal,
    DeleteTransactionModal,
    AddAccountModal,
  },
  data() {
    return {
      show: false,
      searchTableCategory: null,
      selectAccountCat: undefined,
      dataRange: undefined,
      selectStatus: null,
      startDate: undefined,
      endDate: undefined,
      transactionCategory: null,
      selectedAccount: null,
      accounts: [],
      searchedAccounts: [],
      allAccounts: [],
      accountOptions: [],
      accountCategoryOptions: [],
      filteredAccountCategoryOptions: [],
      dateRange: undefined,
      dateRangeOptions: [
        "This financial year",
        "Last financial year",
        "Last year Q1",
        "Last year Q2",
        "Last year Q3",
        "Last year Q4",
      ],
      selectedAccountType: undefined,
      AccountType: "All",
      accountTypeOptions: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "Income",
          value: "income",
        },
        {
          text: "Expense",
          value: "expense",
        },
      ],
      selectedType: undefined,
      selectedCategory: undefined,
      toCategoryUpdate: undefined,
      isShowDeleteItemRow: false,
      isShowAddItemRow: false,
      showDeleteTransactionModalKey: Number(new Date()) + 100,
      showAddTransactionModalKey: Number(new Date()) + 50,
      showAddAccountModalKey: Number(new Date()) + 500,
      rowItem: null,
      defaultContent: {},
      defaultDate: null,
      rowIndex: null,
      filterDropDownShow: false,
      selectedRegion: {
        title: null,
        code: null,
        flagCode: null,
        languageCode: null,
      },
      isRegionFilter: false,
      searchTable: undefined,
      searchAccountText: null,
      isLoading: true,
      selected: [],
      stopRowSelect: false,
      currentPage: null,
      nextPage: 1,
      currentDate: null,
      isShowLoadMore: false,
      transactionsHeader: [
        {
          key: "selected",
          label: "",
          class: "text-left",
          thStyle: { width: "2%" },
        },
        {
          key: "isBasic",
          label: "",
          class: "text-left",
          thStyle: { width: "2%" },
        },
        {
          key: "transactionDate",
          label: "Date",
          class: "text-left",
          sortable: true,
          thStyle: { width: "10%" },
        },
        {
          key: "transactionDescription",
          sortable: true,
          label: "Description",
          class: "text-left",
          thClass: "desWidth",
        },
        {
          key: "transactionCategory",
          label: "Category",
          class: "text-left",
          sortable: true,
          thStyle: { width: "25%" },
        },
        {
          key: "transactionAmount",
          label: "Amount",
          class: "text-right",
          thStyle: { width: "10%" },
        },
        {
          key: "actions",
          label: "Reconciled",
          class: "text-left",
          thStyle: { width: "5%" },
        },
      ],
      transactionsItems: [],
      currency: localStorage.getItem("CURRENCY"),
      selectedRows: [],
      totalBalance: "0.00",
      anchorCurrency: "",
      totalAmountList: [],
    };
  },
  watch: {
    searchTable() {
      this.searchFn();
    },
    selected(val) {
      this.selectStatus = val.length > 0 ? "selectAll" : "unselectAll";
    },
    searchAccountText(val) {
      this.searchInAccounts(val);
    },
    startDate() {
      this.endDate = undefined;
    },
    searchTableCategory() {
      this.searchFnCategory();
    },
    selectedAccount(val) {
      console.log(val);
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.initFn();
    this.getCurrentDate();
    this.isLoading = false;
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole",
    }),
    minEndDate() {
      return this.startDate;
    },
    computedFilterValidations() {
      return !!(
        this.selectedAccount ||
        this.searchTable ||
        this.selectedCategory ||
        this.selectedType ||
        this.startDate ||
        this.endDate ||
        this.dateRange
      );
    },
    computedfilterCount() {
      let count = 0;
      if (this.selectedAccount !== null && this.selectedAccount !== undefined)
        count++;
      if (
        this.selectedCategory !== "" &&
        this.selectedCategory !== null &&
        this.selectedCategory !== undefined
      )
        count++;
      if (this.selectedType !== undefined) count++;
      if (this.searchTable !== undefined && this.searchTable !== "") count++;
      if (this.startDate !== undefined) count++;
      if (this.endDate !== undefined) count++;
      return count;
    },
    computedAccountOptions() {
      let text = this.searchAccountText?.toLowerCase().split(" ");
      let categories = this.accountOptions;
      if (text && categories) {
        return categories.options.map((category) => {
          return {
            ...category,
            options: category.options.filter(function (account) {
              return text.every(function (el) {
                return account.text.toLowerCase().indexOf(el) > -1;
              });
            }),
          };
        });
      } else return categories ? categories.options : [];
    },
    displayTotal() {
      return this.selectedAccount
        ? this.computeAccountTotal(this.selectedAccount.id).replace(
            this.anchorCurrency,
            ""
          )
        : this.totalBalance;
    },
  },
  methods: {
    computeAccountTotal(accId) {
      for (let i = 0; i < this.totalAmountList.length; i++) {
        if (this.totalAmountList[i].id === accId) {
          return (
            this.anchorCurrency +
            " " +
            this.totalAmountList[i].amount.toLocaleString("en-GB", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          );
        }
      }
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
    getDataRange(val) {
      this.dateRange = val;
      this.dataRange = val;
    },
    getAccount(val) {
      this.selectAccountCat = val.text;
      this.selectedCategory = val.value;
    },
    getAccountTypes(val) {
      this.selectedAccountType = val.text;
      this.selectedType = val.value;
    },
    async loadCurrentWorkspace() {
      let { data } = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    numberFormat(val) {
      return formatNumbers(val);
    },
    async initFn() {
      try {
        await this.getTransactions();
      } catch (error) {
        console.log(error);
      }

      try {
        let { data } = await GetExcludeCAB();
        this.accounts = data.chart.chart;
        let accountsArray = [];
        for (const account in this.accounts) {
          for (const sub in this.accounts[account].sub) {
            const options = [];
            const label = this.accounts[account].sub[sub].title;
            if (label === "Cash and Bank") {
              continue;
            }
            for (const supersub in this.accounts[account].sub[sub].sub) {
              const value = {
                type: this.accounts[account].title,
                subcategory: this.accounts[account].sub[sub].title,
                account: this.accounts[account].sub[sub].sub[supersub].title,
              };

              const glCode =
                this.accounts[account].sub[sub].sub[supersub].gl_code;
              if (glCode === undefined) {
                continue;
              }

              options.push({
                value,
                text:
                  this.accounts[account].sub[sub].sub[supersub].title +
                  " [" +
                  this.accounts[account].sub[sub].sub[supersub].gl_code +
                  "]",
              });
            }
            if (options.length > 0) accountsArray.push({ label, options });
          }
        }
        this.accountCategoryOptions = accountsArray;
        this.filteredAccountCategoryOptions = this.accountCategoryOptions;
      } catch (error) {
        console.log(error);
      }

      try {
        let { data } = await GetCashAndBank();
        var accounts = data.data;
        let accountsArray = [];
        accounts.forEach((account) => {
          accountsArray.push({
            value: {
              id: account.id,
              type: account.group,
              subcategory: account.subgroup,
              account: account.account,
              glcode: account.glcode,
            },
            text: account.account + " [" + [account.glcode] + "]",
          });
        });
        let options = [{ label: accounts[0].subgroup, options: accountsArray }];
        this.accountOptions = { options };
      } catch (error) {
        console.log(error);
      }

      try {
        let { data } = await GetAllChartOfAccounts();
        this.allAccounts = data.chart.chart;
      } catch (error) {
        console.log(error);
      }

      try {
        let { data } = await GetAccountTotals();
        this.totalBalance = data.totals.total;
        this.anchorCurrency = data.totals.currency;
        this.totalAmountList = data.totals.accounts;
      } catch (e) {
        console.log(e);
      }
    },
    async loadMoreFn() {
      try {
        await this.getTransactions();
      } catch (error) {
        console.log(error);
      }
    },
    async clearFilterFn() {
      this.selectedCategory = null;
      this.selectAccountCat = undefined;
      this.dateRange = undefined;
      this.selectedAccount = undefined;
      this.searchTable = undefined;
      this.selectedType = undefined;
      this.startDate = undefined;
      this.endDate = undefined;
      this.selectedAccountType = undefined;
      await this.applyFiltersFn();
    },
    async applyFiltersFn(resetPageNumber = true) {
      try {
        if (resetPageNumber) this.nextPage = 1;
        this.transactionsItems = [];
        await this.initFn();
      } catch (error) {
        console.log(error);
      }
    },
    dateFixer(fxDate) {
      const dateObject = new Date(fxDate);
      const year = dateObject.getFullYear();
      let month = dateObject.getMonth() + 1;
      let day = dateObject.getDate();
      if (day < 10) day = `0${day}`;
      if (month < 10) month = `0${month}`;
      return `${day}/${month}/${year}`;
    },
    async getTransactions() {
      this.isShowLoadMore = true;
      try {
        let { data } = await GetAllFilters({
          limit: 10,
          text: this.searchTable,
          start_date: this.startDate,
          end_date: this.endDate,
          dateRange: this.dateRange,
          type: this.selectedType,
          account: this.selectedAccount
            ? `${this.selectedAccount.type.toLowerCase()},${
                this.selectedAccount.subcategory
              },${this.selectedAccount.account}`
            : undefined,
          category: this.selectedCategory
            ? `${this.selectedCategory.type},${this.selectedCategory.subcategory},${this.selectedCategory.account}`
            : undefined,
          page: this.nextPage,
        });
        this.nextPage++;
        if (data.next) {
          this.nextPage = data.next.page;
        } else this.isShowLoadMore = false;
        const newTransactionsItems = data.results.map((x) => {
          return {
            id: x._id,
            isBasic: x.is_basic,
            transactionDate: x.date ? this.dateFixer(x.date) : "",
            transactionDescription: x.description,
            transactionAccount: x.account_name,
            transactionCategory: x.other_accounts[0]?.split(",")[2],
            transactionAmount: {
              amount: x.total_amount,
              type: x.type,
            },
            actions: {
              id: x._id,
              isReviewed: x.reviewed,
            },
          };
        });
        let items = this.selected;
        this.transactionsItems = [
          ...this.transactionsItems,
          ...newTransactionsItems,
        ];
        setTimeout(() => this.initRowsSelected(items), 100);
        // this.initRowsSelected(items);
        this.catchSimillarItems();
        if (this.transactionsItems.length >= data.total) {
          this.isShowLoadMore = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async markAsReviewedFn(trans_id, isReviewed) {
      if (isReviewed === true) {
        try {
          let payload = { id: trans_id, reviewed: false };
          await UpdateTransaction(payload);
          await this.applyFiltersFn();
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let payload = { id: trans_id, reviewed: true };
          await UpdateTransaction(payload);
          await this.applyFiltersFn();
        } catch (error) {
          console.log(error);
        }
      }
    },
    openDeleteTransactionModal(button) {
      this.isShowDeleteItemRow = true;
      this.rowItem = this.selected;
      this.$root.$emit(
        "bv::show::modal",
        "delete-transaction-row-modal",
        button
      );
    },
    onCloseDeleteTransactionModal() {
      this.isShowDeleteItemRow = false;
    },
    onCloseAddTransactionModal() {
      this.isShowAddItemRow = false;
      this.rowItem = null;
      this.defaultContent = null;
    },
    async callInitFn() {
      this.applyFiltersFn();
      this.showDeleteTransactionModalKey = Number(new Date()) + 100;
      this.showAddTransactionModalKey = Number(new Date()) + 50;
      this.showAddAccountModalKey = Number(new Date()) + 500;
    },
    openAddTransactionModal(button, defaultType) {
      this.isShowAddItemRow = true;
      this.$root.$emit("bv::show::modal", `add-transaction-modal`, button);
      if (this.selected.length === 0) {
        this.defaultContent = {
          defaultType: defaultType,
          currentDate: this.currentDate,
        };
      }
      this.rowItem = this.selected;
    },
    initRowsSelected(items) {
      this.stopRowSelect = true;
      this.transactionsItems.forEach((item, index) => {
        items.forEach((selectedItem) => {
          if (item.id === selectedItem.id) {
            this.$refs.selectableTable.selectRow(index);
          }
        });
      });
      this.stopRowSelect = false;
    },
    onRowSelected(items) {
      if (!this.stopRowSelect) {
        this.selected = items;
      }
    },
    selectAllRows() {
      if (this.selectStatus === "selectAll") {
        this.$refs.selectableTable.selectAllRows();
      } else {
        this.clearSelected();
      }
    },
    catchSimillarItems() {
      let temp = [];
      let tempItems = [];
      let same = [];
      this.transactionsItems.forEach((item) => {
        if (temp.includes(item.id)) same.push(item.id);
        else {
          temp.push(item.id);
          tempItems.push(item);
        }
      });
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    getCurrentDate() {
      let today = new Date();
      let month = today.getMonth() + 1;
      let day = today.getDate();
      if (day < 10) day = "0" + day;
      if (month < 10) month = "0" + month;
      this.currentDate = today.getFullYear() + "-" + month + "-" + day;
    },
    onSearch() {
      let params = { text: this.searchTable };
      SearchTransactions(params)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    },
    onSearchMore() {
      let params = { text: this.searchTable };
      SearchTransactions(params)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    },
    openAddAccountModal(button) {
      this.$root.$emit("bv::show::modal", `add-account-modal`, button);
    },
    searchInAccounts(text) {
      text = text.toLowerCase().split(" ");
      let categories = this.accountOptions;
      if (categories) {
        categories.options.map((category) => {
          return {
            ...category,
            options: category.options.filter(function (account) {
              return text.every(function (el) {
                return account.text.toLowerCase().indexOf(el) > -1;
              });
            }),
          };
        });
      }
    },
    async changeTransactionCategory(cate) {
      let count = 0;
      
      for (let i = 0; i < this.selected.length; i++) {
        // const item = this.transactionsItems.find(
        //   (transaction) => transaction.id === this.selected[i].id
        // );

        const payload = {
          id: this.selected[i].id,
          category:
            cate.value.type +
            "," +
            cate.value.subcategory +
            "," +
            cate.value.account,
          date: this.formatDates(this.selected[i].transactionDate),
          description: this.selected[i].transactionDescription,
        
          
        };
        console.log("transactiondate", this.selected[i])
        await UpdateTransaction(payload)
          .then((res) => {
            if (res.status === 200) {
              count++;
            }
          })
          .catch(() => {
            let payloadNotify = {
              isToast: true,
              title: "Failed!",
              content: "Failed to update transaction " + payload.id,
              variant: "error",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          });
      }
      let payloadNotify = {
        isToast: true,
        title: count + " Transactions Updated!",
        content: "Category Updated Successfully",
        variant: "success",
      };
      this.show = false;
      this.$store.dispatch("notification/setNotify", payloadNotify);
      this.applyFiltersFn();
    },
     formatDates(dateString) {
    // Split the date string by "/"
    const parts = dateString.split("/");

    // Rearrange the parts to form the desired format "YYYY-MM-DD"
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

    return formattedDate;
},
   
    searchFnCategory() {
      const searchTerm = this.searchTableCategory.toLowerCase();
      this.filteredAccountCategoryOptions = this.accountCategoryOptions.filter(
        (account) => {
          const labelMatches = account.label.toLowerCase().includes(searchTerm);
          const subAccountMatches = account.options.some((sub_account) =>
            sub_account.text.toLowerCase().includes(searchTerm)
          );
          return labelMatches || subAccountMatches;
        }
      );
    },
    searchFn() {
     
      if (this.searchTable && this.searchTable.length > 0) {
        // call the init function to load the data based on the search query
        // pass 1 into the function to reset the current page to 1, before executing the search to ensure the search doesn't get hindered by the pagination
        this.applyFiltersFn();
      }else{
        this.initFn();
      }
    },
  },
};
</script>

<style scoped>
.Category {
  background: #0b9b7d;
  border: none;
}

.types {
  width: 215px !important;
  margin: 0 20px !important;
}

::placeholder {
  color: #0b9b7d;
  opacity: 1;
}

.date-fields-style {
  border: 1.8px solid #0b9b7d;
}

.desWidth {
  width: 80%;
}

.count-style {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  color: #0b9b7d;
  background-color: #f7fbfc;
  font-weight: 500;
  margin-top: -1px;
  padding-left: 10px;
  padding-top: 2px;
}

.disabled-button {
  background-color: #0b9b7d;
  background: rgba(33, 150, 83, 0.5);
  color: #ffffff;
  border-color: #ffffff;
}

.date-picker-btn >>> .btn .b-icon.bi {
  color: #0b9b7d;
}

.date-picker-btn {
  border: 1.8px solid;
  border-color: #0b9b7d;
  border-radius: 0px 3px 3px 0px;
}

.account-dropdown {
  max-height: 540px;
  overflow: auto;
}

.position-relative {
  position: relative;
}

.position-relative .dropdown-over-table {
  z-index: 9999;
  max-height: none !important;
  height: auto !important;
  overflow: visible !important;
}

.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 0;
  border: 1px solid var(--light);
}

.table-card-row .search-input-group {
  box-shadow: none;
  height: 40px;
  width: 180px;
}

.CategorySearch {
  height: 50px;
  border-radius: 5px;
}

.CategorySearch .categorySearch-input {
  box-shadow: none;
  height: 50px;
  border-radius: 5px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 40px;
  border-radius: 5px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: #0b9b7d;
}

.table-card-row .category-dropdown,
.table-card-row .types-dropdown,
.table-card-row .accounts-dropdown,
.table-card-row .date-picker,
.table-card-row .add-question-btn {
  height: 40px;
}

.table-card-row .add-question-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
  color: #ffffff;
  border: none;
}

.table-card-row .accounts-dropdown {
  min-width: 295px;
}

.table-card-row .category-dropdown {
  min-width: 310px;
  padding-left: 15px;
}

.table-card-row .types-dropdown {
  min-width: 310px;
}

.table-card-row .startdate-picker {
  width: 200px;
  border: 1.8px solid #0b9b7d;
  color: #0b9b7d;
}

.table-card-row .enddate-picker {
  width: 200px;
  border: 1.8px solid #0b9b7d;
}

.table-card-row .b-form-datepicker.form-control:focus {
  color: #0b9b7d;
  background-color: #fff;
  border-color: #0b9b7d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 83, 0.5);
}

.table-card-row
  .b-form-datepicker
  >>> .custom-calendar-dropdown
  .b-calendar-grid.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #0b9b7d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 83, 0.5);
}

.table-card-row .category-dropdown .types-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row .types-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.table-card-row .apply-filters-btn {
  transition: color 200ms ease-in;
}

.table-card-row .apply-filters-btn:hover {
  background-color: #0b9b7d;
}

.clearAll-text-color {
  color: #0b9b7d;
}

.clearAll-text-color:hover {
  color: #0b9b7d;
}

.vertical-bar {
  margin-right: 19px;
  margin-left: 5px;
  margin-top: 2px;
  color: #bdbdbd;
}

.table-container-row .load-more-btn {
  transition: color 200ms ease-in;
  color: #0b9b7d;
}

.table-container-row .load-more-btn:hover {
  color: #0b9b7d;
  text-decoration: none;
}

.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(33, 150, 83, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 1000px;
  width: 100%;
  height: auto;
  scrollbar-width: thin;
}

.table-element >>> .table-header {
  background-color: transparent;
}

.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
}

.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

.table-element >>> .table-body .question-row {
  max-width: 550px;
}

.table-element >>> .table-body .actions-row {
  min-width: 140px;
}

.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: #0b9b7d;
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}

.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  border: hidden;
  background-color: transparent;
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  color: #0b9b7d;
  transition: color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .reviewed-icon {
  color: var(--success);
  transition: color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: #0b9b7d;
}

.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}

.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

.text-color {
  color: #0b9b7d;
}

@media (max-width: 1346px) and (min-width: 1170px) {
  .category-field {
    max-width: 350px;
  }

  .type-field {
    max-width: 200px;
  }
}

@media (max-width: 1170px) and (min-width: 1118px) {
  .category-field {
    max-width: 330px;
  }

  .type-field {
    max-width: 200px;
  }
}

@media (max-width: 1118px) and (min-width: 1060px) {
  .category-field {
    max-width: 310px;
  }

  .type-field {
    max-width: 188px;
  }
}

@media (max-width: 1060px) and (min-width: 992px) {
  .category-field {
    max-width: 270px;
  }

  .type-field {
    max-width: 160px;
  }

  .table-container-row .edit-delete-row {
    min-width: 100px;
  }
}

@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .table-card-row .category-dropdown {
    min-width: 330px;
  }

  .table-card-row .types-dropdown {
    min-width: 330px;
  }

  .table-container-row .edit-delete-row {
    min-width: 180px;
  }
}
</style>
